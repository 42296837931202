import { createSlice } from '@reduxjs/toolkit';
import { onChangeColorMask, PARKING_CATEGORIES } from '../components/parking/parkingFunctionsHelper';

const initialState = {
  data: null,
  availableParkings: [],
  filteredParkings: [],
  categories: null,
  priceRange: null,
  showResult: false,
  isListView: false,
};

const parkingSlice = createSlice({
  name: 'parking',
  initialState,
  reducers: {
    setParkingData: (state, action) => {
      state.data = action.payload;
      state.availableParkings = action.payload?.flats ?? [];
      state.filteredParkings = state.availableParkings;
      const initialCategories = Object.entries(PARKING_CATEGORIES).map(([key, value]) => ({
        ...value,
        active: false,
      }));
      state.categories = initialCategories;
    },
    setShowResult: (state, action) => {
      state.showResult = action.payload;
    },
    onChangeCategory: (state, action) => {
      const { name, active } = action.payload;

      state.categories = state.categories.map(item => (item.name === name ? { ...item, active: !item.active } : item));
      let activeCategories = state.categories.filter(c => c.active).map(c => c.name);
      if (active) activeCategories = activeCategories.filter(categoryName => categoryName !== name);
      else activeCategories.push(name);

      parkingSlice.caseReducers.onApplyFilters(state);
    },
    onChangePrice: (state, action) => {
      const { range, min, max } = action.payload;
      const roundedValues = range.map(value => Math.round(value));

      if (roundedValues[0] === min && roundedValues[1] === max) {
        state.priceRange = null;
      } else {
        state.priceRange = roundedValues;
      }

      parkingSlice.caseReducers.onApplyFilters(state);
    },
    onApplyFilters: state => {
      let filteredList = [...state.availableParkings];

      // Фильтрация по категориям
      const activeCategories = state.categories.filter(c => c.active && c.value !== 'all').map(c => c.name);
      if (activeCategories.length > 0) {
        filteredList = filteredList.filter(parking => {
          const parkingCategoryTitle = onChangeColorMask(parking.attributesData.formaParking).name;
          return activeCategories.includes(parkingCategoryTitle);
        });
      }

      // Фильтрация по цене
      if (state.priceRange) {
        filteredList = filteredList.filter(place => {
          const price = place.price / 1e6;
          return price >= state.priceRange[0] && price <= state.priceRange[1];
        });
      }

      const isAllInactive = state.categories.every(i => !i.active);

      if (isAllInactive && !state.priceRange) {
        state.filteredParkings = [...state.availableParkings];
      } else {
        state.filteredParkings = filteredList;
      }
    },
    onResetFilters: state => {
      state.filteredParkings = state.availableParkings;
      const initialCategories = Object.entries(PARKING_CATEGORIES).map(([key, value]) => value);
      state.categories = initialCategories;
      state.priceRange = null;
      state.showResult = true;
    },
    onChangeListView: (state, action) => {
      state.isListView = action.payload;
    },
  },
});

export const {
  setParkingData,
  onChangeCategory,
  onChangePrice,
  onApplyFilters,
  onResetFilters,
  setShowResult,
  onChangeListView,
} = parkingSlice.actions;
export default parkingSlice.reducer;
