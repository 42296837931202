import './RotateCaution.scss';

const RotateCaution = () => {
  return (
    <div className='RotateCaution'>
      Пожалуйста, переверните устройство.
      <br />
      Это необходимо для корректного
      <br />
      отображения сайта.
    </div>
  );
};

export default RotateCaution;
