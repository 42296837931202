import './MainLoader.scss';

const MainLoaderFake = () => {
  return '';
};

const MainLoader = props => {
  return (
    <div className={`nl_reploader_father${props.isLoading ? '--hide' : ''} ${props.innerLoader ? 'innerLoader' : ''}`}>
      <div className='nl_preloader'>
        <div className='loader'>Loading...</div>
      </div>
    </div>
  );
};

export default typeof window.is_server !== typeof undefined ? MainLoaderFake : MainLoader;
