export const calculatePositionTooltip = (pathElement, tooltipEl) => {
  const rect = pathElement.getBoundingClientRect();
  const { innerWidth, innerHeight } = window;
  const tooltipElement = tooltipEl.current;
  const widthTooltip = tooltipElement?.offsetWidth || 418;
  const heightTooltip = tooltipElement?.offsetHeight || 400;

  let top = rect.top;
  let left = rect.left;

  // Позиционирование справа от элемента
  if (left + rect.width + widthTooltip > innerWidth) left -= widthTooltip;
  else left += rect.width;

  if (top + heightTooltip > window.innerHeight) {
    top = rect.top - heightTooltip;
  }
  return { top, left };
};

export const getFractionalNum = place => {
  // Получаем место через дробь, например, 165/166
  if (place.name.split(', ').length > 1) {
    return `${place.number}/${Number(place.number) + 1}`;
  }
  return place.number;
};

export const getYearAndQuarter = dateString => {
  const convertToRoman = num => {
    const roman = ['I', 'II', 'III', 'IV'];
    return roman[num - 1];
  };

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const quarter = Math.floor(month / 3) + 1;

  return { year, quarter: convertToRoman(quarter) };
};

export const formatParkingPlaces = count => {
  if (count === 0 || !count) return '0 машино-мест';

  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) return `${count} машино-мест`;
  if (lastDigit === 1) return `${count} машино-место`;
  if (lastDigit >= 2 && lastDigit <= 4) return `${count} машино-места`;
  return `${count} машино-мест`;
};

export const onChangeColorMask = formaParking => {
  const standart = formaParking.standartnoe?.value;
  const largeSize = formaParking.sUvelichennymiGabaritami?.value;
  const familyStandart = formaParking.semejnoe?.value;
  const familyLargeSize = formaParking.semejnoeSUvelichennymiGabaritami?.value;

  if ((familyLargeSize && largeSize) || familyLargeSize) {
    return PARKING_CATEGORIES.familyLargeSize;
  }
  if ((standart && familyStandart) || familyStandart) {
    return PARKING_CATEGORIES.familyStandart;
  }
  if (largeSize) {
    return PARKING_CATEGORIES.largeSize;
  }
  if (standart) {
    return PARKING_CATEGORIES.standart;
  }
};

// Наименования для видов парковки и их цвета
export const PARKING_CATEGORIES = {
  standart: { name: 'Стандартное', fill: '#4EB9F7' },
  largeSize: { name: 'Крупногабаритное', fill: '#AE7AEF' },
  familyStandart: { name: 'Семейное стандартное', fill: '#EBD717' },
  familyLargeSize: { name: 'Семейное крупногабаритное', fill: '#16BC32' },
};
