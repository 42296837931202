import './MainHeader.scss';
import useAxios from 'axios-hooks';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useLocation } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import { HEADER_IPAD_PRO_22489, PARKING_FEATURE_ENABLED } from '../../../featureFlags';
import { handleOpen, setScrollPosition } from '../../../state/componentsSlice';
import { setHeight, setWidth } from '../../../state/mainSlice';
import { getHeight, getPhoneNumber, getScrollPosition, getTabletWidth, getWidth } from '../../../state/selectors';
import { disableBodyScroll } from '../../../tools/_body-scroll-lock';
import MainPageSvg from '../../svgs/MainPageSvg';

const OFFSET_Y = 60;

const MainHeader = () => {
  const width = useSelector(getWidth);
  const widthTablet = useSelector(getTabletWidth);
  const height = useSelector(getHeight);
  const scrollPosition = useSelector(getScrollPosition);
  const [visibleHeaderPortland, setVisibleHeaderPortland] = useState(true);
  const [backgroundGreyHeader, setBackgroundGreyHeader] = useState(false);
  const [backgroundWhiteHeader, setBackgroundWhiteHeader] = useState(false);
  const [isTopPosition, setIsTopPosition] = useState(true);
  const [visible, setVisible] = useState(true);
  const [mainPage, setMainPage] = useState(false);
  const [isAdaptive, setIsAdaptive] = useState(false);

  const isTabletPro = useMediaQuery({
    query: '(min-width: 540px) and (max-width: 1370px) and (orientation: portrait)',
  });

  useEffect(() => {
    if (HEADER_IPAD_PRO_22489) {
      setIsAdaptive(width <= widthTablet || isTabletPro);
    } else {
      setIsAdaptive(width <= widthTablet);
    }
  }, [width, widthTablet, isTabletPro]);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setScrollPosition(1));
  }, [pathname]);

  //Управление меню-бургером
  const showBurger = () => {
    dispatch(handleOpen({ page: 'burger-menu', value: true }));
    disableBodyScroll();
  };

  const favoriteList = useSelector(state => state.favoritePage.favoriteList);

  const { scroll } = useLocomotiveScroll();

  useEffect(() => {
    //Сохранение позиции скролла при использовании Locomotive-scroll
    if (scroll) {
      scroll.on('scroll', () => {
        dispatch(setScrollPosition(scroll.scroll.instance.scroll.y));
        setIsTopPosition(scroll.scroll.instance.scroll.y < OFFSET_Y);
      });
    }
  }, [scroll]);

  useEffect(() => {
    //Сохранение позиции скролла при дефолтном скролле экрана и управление видимости шапкой с якорем
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        if (window.scrollY > scrollPosition) {
          setVisible(false);
        } else {
          setVisible(true);
        }
        // remember current page location to use in the next move
        dispatch(setScrollPosition(window.scrollY));
        setIsTopPosition(window.scrollY < OFFSET_Y);
      };

      window.addEventListener('scroll', () => handleScroll());

      return () => {
        window.removeEventListener('scroll', () => handleScroll());
      };
    }
  }, [scrollPosition]);

  //Сохранение высоты при ее изменении
  useLayoutEffect(() => {
    dispatch(setHeight(window.innerHeight));
    dispatch(setWidth(window.innerWidth));
  }, [height !== window.innerHeight, width !== window.innerWidth, window.screen.orientation]);

  //Находимся ли мы в крайней верхней точки страницы?
  useLayoutEffect(() => {
    setIsTopPosition(scrollPosition < OFFSET_Y);
  }, [scrollPosition < OFFSET_Y]);

  //Анимация смены шапок с дефолтной на якорную на мобильном
  useLayoutEffect(() => {
    if (isAdaptive && scrollPosition > OFFSET_Y) {
      setVisibleHeaderPortland(visible);
    }
  }, [scrollPosition > OFFSET_Y, visible]);

  const getSubstrateDropdownMenu = () => {
    const visualFirstLevel = /\/visual\/[^/]+\/?$/;
    const visualSecondLevel = /\/visual\/[^/]+\/[^/]+/;

    const isBlurBg =
      ((pathname.includes('/team') ||
        pathname.includes('/landscaping') ||
        pathname.includes('/infrastructure') ||
        pathname.includes('/architecture') ||
        pathname.includes('/seafront')) &&
        width <= 1385) ||
      visualFirstLevel.test(pathname) ||
      pathname.includes('/commercial');
    const isWhiteBg =
      (visualSecondLevel.test(pathname) || pathname.includes('/flat/') || pathname.includes('/list')) && !isAdaptive;
    const isDefaultBg =
      (pathname.includes('/purchase-terms') ||
        pathname.includes('/favorites') ||
        pathname.includes('/progress') ||
        pathname.includes('/news')) &&
      !isAdaptive;

    if (isBlurBg) {
      return { background: '#141D2733', '-webkit-backdrop-filter': 'blur(7.5px)', backdropFilter: 'blur(7.5px)' };
    }
    if (isWhiteBg) {
      return { backgroundColor: '#fff' };
    }
    if (isDefaultBg) {
      return { backgroundColor: '#f1f1f1' };
    }
    return {};
  };

  useEffect(() => {
    if (pathname === '/list' && pathname.includes('/visual') && isAdaptive) {
      setBackgroundWhiteHeader(scrollPosition > OFFSET_Y);
    }

    if (pathname === '/') {
      setMainPage(true);
    }

    if (
      (pathname.includes('/about') ||
        pathname.includes('/commercial') ||
        pathname.includes('/infrastructure') ||
        pathname.includes('/news') ||
        pathname.includes('/purchase-terms') ||
        pathname.includes('/cashback') ||
        pathname.includes('/gallery') ||
        pathname.includes('/documents') ||
        pathname.includes('/parking') ||
        pathname.includes('/landscaping') ||
        pathname.includes('/architecture') ||
        pathname.includes('/seafront') ||
        pathname.includes('/team') ||
        pathname === '/' ||
        pathname.includes('/progress') ||
        pathname.split('/')[1] === 'progress') &&
      !isAdaptive
    ) {
      setBackgroundGreyHeader(scrollPosition > OFFSET_Y);
    }

    return () => {
      setBackgroundGreyHeader(false);
      setBackgroundWhiteHeader(false);
      setMainPage(false);
    };
  }, [setIsTopPosition, scrollPosition, pathname, dispatch, isAdaptive]);

  const getHeaderClasses = () => {
    let classes = 'MainHeader MainHeader--portland';
    classes += visibleHeaderPortland && isTopPosition ? ' Visible' : '';
    classes += (!visibleHeaderPortland || !isTopPosition) && isAdaptive ? ' Hidden' : '';
    classes += backgroundGreyHeader ? ' Grey' : '';
    classes += backgroundWhiteHeader ? ' White' : '';
    return classes;
  };

  const getWrapperClasses = () => {
    let classes = 'MainHeader__wrapper MainHeader__wrapper--portland';
    classes += mainPage && (isTopPosition || isAdaptive) ? ' MainHeader__wrapper--main' : '';
    classes += isTopPosition ? ' TopPosition' : ' NotTopPosition';
    return classes;
  };

  const getAnchorHeaderClasses = () => {
    let classes = 'MainHeader MainHeader--anchor';
    classes += visible && !isTopPosition ? ' Visible' : ' Hidden';
    classes += backgroundGreyHeader ? ' Grey' : '';
    classes += backgroundWhiteHeader ? ' White' : '';
    return classes;
  };

  return (
    <>
      <nav className={getHeaderClasses()} data-scroll data-scroll-sticky data-scroll-target='#App'>
        <div className={getWrapperClasses()}>
          <NavLink to={`${ROUTES.root}`} className='MainHeader__item MainHeader__item--logo'>
            <MainPageSvg id='hero-logo' addClass='MainHeader__logo' />
          </NavLink>
          {isAdaptive ? (
            <MobileHeaderItems favoritesCount={favoriteList.length} showBurger={showBurger} />
          ) : (
            <DesktopHeaderItems
              favoritesCount={favoriteList.length}
              showBurger={showBurger}
              isSubstrateDropdown={getSubstrateDropdownMenu}
            />
          )}
        </div>
      </nav>
      {/*Шапка с якорем для телефонов*/}
      {isAdaptive && (
        <MobileAnchorHeader
          favoritesCount={favoriteList.length}
          showBurger={showBurger}
          getAnchorHeaderClasses={getAnchorHeaderClasses}
        />
      )}
    </>
  );
};

const DesktopHeaderItems = props => {
  const { favoritesCount, showBurger, isSubstrateDropdown } = props;
  const phone = useSelector(getPhoneNumber);
  const [showDropdown, setShowDropdown] = useState(false);
  const [{ data: headerMenu }] = useAxios({ url: [`/api/menu/top`] });
  const isMenuReady = headerMenu && headerMenu.menuItems;

  return (
    <>
      {PARKING_FEATURE_ENABLED ? (
        <>
          {isMenuReady && (
            <div className='MainHeader__MenuDropdown-parent' onClick={() => setShowDropdown(!showDropdown)}>
              <>
                <div className={`MainHeader__item ${showDropdown ? 'MainHeader__MenuDropdown-opacity' : ''}`}>
                  <span>{headerMenu.menuItems[0].value}</span>
                  <div data-testid='Header_Favorite' className='MainHeader__favorite'>
                    <svg
                      className='MainHeader__favorite-icon'
                      width='13'
                      height='10'
                      viewBox='0 0 13 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M6.67952 9.04838H6.29057L0 2.69941V0.0241699L0.438821 0.0241699L5.32067 4.98887L6.47507 6.04834L7.62946 5.02921L12.5861 0.0241699L13 0.0241699V2.69941L6.67952 9.04838Z'
                        fill='white'
                      />
                    </svg>
                  </div>
                </div>
                {showDropdown && headerMenu.menuItems[0].child.length > 0 && (
                  <div className='MainHeader__MenuDropdown-block' style={isSubstrateDropdown()}>
                    {headerMenu.menuItems[0].child.map(child => {
                      return (
                        <NavLink key={child.path} to={child.path} className='MainHeader__item'>
                          {child.value}
                        </NavLink>
                      );
                    })}
                  </div>
                )}
              </>
            </div>
          )}
          {isMenuReady &&
            headerMenu.menuItems.map((item, i) => {
              const { value, child, path } = item;
              return (
                child.length === 0 && (
                  <NavLink key={i} to={path} className='MainHeader__item'>
                    {value}
                  </NavLink>
                )
              );
            })}
        </>
      ) : (
        headerMenu &&
        headerMenu.menuItems &&
        headerMenu.menuItems.map((item, i) => {
          return (
            <NavLink key={i} to={item.path} className='MainHeader__item'>
              {item.value}
            </NavLink>
          );
        })
      )}

      <a href={`tel:${phone.link}`} className='MainHeader__item MainHeader__item--phone-number'>
        {phone.text}
      </a>
      <div className='MainHeader__right-block'>
        {favoritesCount !== 0 && (
          <div data-testid='Header_Favorite' className='MainHeader__favorite'>
            <NavLink to={`${ROUTES.favorites.root}`} className='MainHeader__item'>
              <svg
                className='MainHeader__favorite-icon'
                width='24'
                height='23'
                viewBox='0 0 24 23'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M23.0508 5.23713C22.6843 4.38848 22.1558 3.61944 21.4949 2.97307C20.8336 2.32476 20.0538 1.80956 19.198 1.45549C18.3107 1.08688 17.3589 0.898198 16.398 0.90041C15.05 0.90041 13.7348 1.26955 12.5918 1.96682C12.3184 2.13361 12.0586 2.31682 11.8125 2.51643C11.5664 2.31682 11.3066 2.13361 11.0332 1.96682C9.89023 1.26955 8.575 0.90041 7.22695 0.90041C6.25625 0.90041 5.31562 1.08635 4.42695 1.45549C3.56836 1.81096 2.79453 2.32228 2.13008 2.97307C1.46835 3.61871 0.939744 4.38793 0.574219 5.23713C0.194141 6.12033 0 7.05822 0 8.02346C0 8.934 0.185938 9.88283 0.555078 10.8481C0.864063 11.6547 1.30703 12.4914 1.87305 13.3363C2.76992 14.6735 4.00312 16.068 5.53437 17.4817C8.07187 19.825 10.5848 21.4438 10.6914 21.5094L11.3395 21.925C11.6266 22.1082 11.9957 22.1082 12.2828 21.925L12.9309 21.5094C13.0375 21.441 15.5477 19.825 18.0879 17.4817C19.6191 16.068 20.8523 14.6735 21.7492 13.3363C22.3152 12.4914 22.7609 11.6547 23.0672 10.8481C23.4363 9.88283 23.6223 8.934 23.6223 8.02346C23.625 7.05822 23.4309 6.12033 23.0508 5.23713ZM11.8125 19.7621C11.8125 19.7621 2.07812 13.525 2.07812 8.02346C2.07812 5.23713 4.3832 2.97853 7.22695 2.97853C9.22578 2.97853 10.9594 4.09416 11.8125 5.72385C12.6656 4.09416 14.3992 2.97853 16.398 2.97853C19.2418 2.97853 21.5469 5.23713 21.5469 8.02346C21.5469 13.525 11.8125 19.7621 11.8125 19.7621Z'
                  fill='#141D27'
                />
              </svg>
              <div className='MainHeader__favorite-label'>{favoritesCount}</div>
            </NavLink>
          </div>
        )}
        <button className='MainHeader__item' onClick={() => showBurger()}>
          {favoritesCount === 0 && <span>меню</span>}
          <div className='MainHeader__item--burger'>
            <MainPageSvg id='header-burger' addClass='MainHeader__item--burger--desktop' />
          </div>
        </button>
      </div>
    </>
  );
};

const MobileHeaderItems = props => {
  const { favoritesCount, showBurger } = props;
  return (
    <>
      <div className='MainHeader__items'>
        {favoritesCount !== 0 && (
          <NavLink to={`${ROUTES.favorites.root}`} className='MainHeader__item'>
            <div data-testid='Header_Favorite' className='MainHeader__favorite'>
              <svg
                className='MainHeader__favorite-icon'
                width='24'
                height='23'
                viewBox='0 0 24 23'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M23.0508 5.23713C22.6843 4.38848 22.1558 3.61944 21.4949 2.97307C20.8336 2.32476 20.0538 1.80956 19.198 1.45549C18.3107 1.08688 17.3589 0.898198 16.398 0.90041C15.05 0.90041 13.7348 1.26955 12.5918 1.96682C12.3184 2.13361 12.0586 2.31682 11.8125 2.51643C11.5664 2.31682 11.3066 2.13361 11.0332 1.96682C9.89023 1.26955 8.575 0.90041 7.22695 0.90041C6.25625 0.90041 5.31562 1.08635 4.42695 1.45549C3.56836 1.81096 2.79453 2.32228 2.13008 2.97307C1.46835 3.61871 0.939744 4.38793 0.574219 5.23713C0.194141 6.12033 0 7.05822 0 8.02346C0 8.934 0.185938 9.88283 0.555078 10.8481C0.864063 11.6547 1.30703 12.4914 1.87305 13.3363C2.76992 14.6735 4.00312 16.068 5.53437 17.4817C8.07187 19.825 10.5848 21.4438 10.6914 21.5094L11.3395 21.925C11.6266 22.1082 11.9957 22.1082 12.2828 21.925L12.9309 21.5094C13.0375 21.441 15.5477 19.825 18.0879 17.4817C19.6191 16.068 20.8523 14.6735 21.7492 13.3363C22.3152 12.4914 22.7609 11.6547 23.0672 10.8481C23.4363 9.88283 23.6223 8.934 23.6223 8.02346C23.625 7.05822 23.4309 6.12033 23.0508 5.23713ZM11.8125 19.7621C11.8125 19.7621 2.07812 13.525 2.07812 8.02346C2.07812 5.23713 4.3832 2.97853 7.22695 2.97853C9.22578 2.97853 10.9594 4.09416 11.8125 5.72385C12.6656 4.09416 14.3992 2.97853 16.398 2.97853C19.2418 2.97853 21.5469 5.23713 21.5469 8.02346C21.5469 13.525 11.8125 19.7621 11.8125 19.7621Z'
                  fill='#141D27'
                />
              </svg>
              <div className='MainHeader__favorite-label'>{favoritesCount}</div>
            </div>
          </NavLink>
        )}
        <button className='MainHeader__item--burger--mobile' onClick={() => showBurger()}>
          <MainPageSvg id='burger-even' />
        </button>
      </div>
    </>
  );
};

const MobileAnchorHeader = props => {
  const { favoritesCount, showBurger, getAnchorHeaderClasses } = props;

  return (
    <nav className={getAnchorHeaderClasses()} data-scroll data-scroll-sticky data-scroll-target='#App'>
      <div className='MainHeader__wrapper'>
        <NavLink to={`${ROUTES.root}`} className='MainHeader__item MainHeader__item--logo'>
          <MainPageSvg id='anchor' addClass='MainHeader__logo--anchor' />
        </NavLink>
        <div className='MainHeader__items'>
          {favoritesCount !== 0 && (
            <NavLink to={`${ROUTES.favorites.root}`} className='MainHeader__item'>
              <div data-testid='Header_Favorite' className='MainHeader__favorite'>
                <svg
                  className='MainHeader__favorite-icon'
                  width='24'
                  height='23'
                  viewBox='0 0 24 23'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M23.0508 5.23713C22.6843 4.38848 22.1558 3.61944 21.4949 2.97307C20.8336 2.32476 20.0538 1.80956 19.198 1.45549C18.3107 1.08688 17.3589 0.898198 16.398 0.90041C15.05 0.90041 13.7348 1.26955 12.5918 1.96682C12.3184 2.13361 12.0586 2.31682 11.8125 2.51643C11.5664 2.31682 11.3066 2.13361 11.0332 1.96682C9.89023 1.26955 8.575 0.90041 7.22695 0.90041C6.25625 0.90041 5.31562 1.08635 4.42695 1.45549C3.56836 1.81096 2.79453 2.32228 2.13008 2.97307C1.46835 3.61871 0.939744 4.38793 0.574219 5.23713C0.194141 6.12033 0 7.05822 0 8.02346C0 8.934 0.185938 9.88283 0.555078 10.8481C0.864063 11.6547 1.30703 12.4914 1.87305 13.3363C2.76992 14.6735 4.00312 16.068 5.53437 17.4817C8.07187 19.825 10.5848 21.4438 10.6914 21.5094L11.3395 21.925C11.6266 22.1082 11.9957 22.1082 12.2828 21.925L12.9309 21.5094C13.0375 21.441 15.5477 19.825 18.0879 17.4817C19.6191 16.068 20.8523 14.6735 21.7492 13.3363C22.3152 12.4914 22.7609 11.6547 23.0672 10.8481C23.4363 9.88283 23.6223 8.934 23.6223 8.02346C23.625 7.05822 23.4309 6.12033 23.0508 5.23713ZM11.8125 19.7621C11.8125 19.7621 2.07812 13.525 2.07812 8.02346C2.07812 5.23713 4.3832 2.97853 7.22695 2.97853C9.22578 2.97853 10.9594 4.09416 11.8125 5.72385C12.6656 4.09416 14.3992 2.97853 16.398 2.97853C19.2418 2.97853 21.5469 5.23713 21.5469 8.02346C21.5469 13.525 11.8125 19.7621 11.8125 19.7621Z'
                    fill='#141D27'
                  />
                </svg>
                <div className='MainHeader__favorite-label'>{favoritesCount}</div>
              </div>
            </NavLink>
          )}
          <button className='MainHeader__item--burger--mobile' onClick={() => showBurger()}>
            <MainPageSvg id='burger-even' />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default MainHeader;
