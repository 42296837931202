const disableBodyScroll = () => {
  setTimeout(() => {
    if (!Object.hasOwn(document.body.dataset, 'bodyScrollFix')) {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      document.body.dataset.bodyScrollFix = scrollPosition;
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = '-' + scrollPosition + 'px';
      document.body.style.left = '0';
      document.body.style.width = '100%';
    }
  }, 15);
};

const enableBodyScroll = () => {
  if (Object.hasOwn(document.body.dataset, 'bodyScrollFix')) {
    const scrollPosition = document.body.dataset.bodyScrollFix;
    delete document.body.dataset.bodyScrollFix;
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.left = '';
    document.body.style.width = '';
    window.scroll(0, scrollPosition);
  }
};

// (FORMA-16777) убирает мерцание при тапе на лот машино-места на планшете и мобилке
const disableParkingBodyScroll = () => {
  setTimeout(() => {
    if (!Object.hasOwn(document.body.dataset, 'bodyScrollFix')) {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      document.body.dataset.bodyScrollFix = scrollPosition;
      document.body.style.overflow = 'hidden';
    }
  }, 15);
};

const enableParkingBodyScroll = () => {
  if (Object.hasOwn(document.body.dataset, 'bodyScrollFix')) {
    delete document.body.dataset.bodyScrollFix;
    document.body.style.overflow = '';
  }
};

export { disableBodyScroll, disableParkingBodyScroll, enableBodyScroll, enableParkingBodyScroll };
