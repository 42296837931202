const ContactsPageSvg = ({ addClass, id }) => {
  switch (id) {
    case 'minus': {
      return (
        <svg
          className={addClass}
          width='54'
          height='54'
          viewBox='0 0 54 54'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect x='54' y='54' width='54' height='54' rx='27' transform='rotate(180 54 54)' fill='white' />
          <rect x='20' y='28' width='2' height='14' transform='rotate(-90 20 28)' fill='#141D27' />
        </svg>
      );
    }
    case 'plus': {
      return (
        <svg
          className={addClass}
          width='54'
          height='54'
          viewBox='0 0 54 54'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='54' height='54' rx='27' fill='white' />
          <rect x='26' y='20' width='2' height='14' fill='#141D27' />
          <rect x='20' y='28' width='2' height='14' transform='rotate(-90 20 28)' fill='#141D27' />
        </svg>
      );
    }
  }
};

export default ContactsPageSvg;
