import React from 'react';

const CatalogPageSvg = props => {
  switch (props.id) {
    case 'close-brown': {
      return (
        <svg
          className={props.addClass}
          width='17'
          height='17'
          viewBox='0 0 17 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.37822 8.70349L3.80688 12.2748L5.10555 13.5735L8.67688 10.0022L12.2482 13.5735L13.5469 12.2748L9.97555 8.70349L13.5469 5.13216L12.2482 3.8335L8.67688 7.40483L5.10556 3.8335L3.80689 5.13217L7.37822 8.70349Z'
            fill='#A68760'
          />
        </svg>
      );
    }
    case 'list-icon': {
      return (
        <svg
          className={props.addClass}
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path fillRule='evenodd' clipRule='evenodd' d='M2 4V6H22V4H2Z' fill='#141D27' />
          <path fillRule='evenodd' clipRule='evenodd' d='M2 18V20H22V18H2Z' fill='#141D27' />
          <path fillRule='evenodd' clipRule='evenodd' d='M2 11V13H22V11H2Z' fill='#141D27' />
        </svg>
      );
    }
    case 'tile-icon': {
      return (
        <svg
          className={props.addClass}
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path fillRule='evenodd' clipRule='evenodd' d='M19 5H15V9H19V5ZM13 3V11H21V3H13Z' fill='#141D27' />
          <path fillRule='evenodd' clipRule='evenodd' d='M19 15H15V19H19V15ZM13 13V21H21V13H13Z' fill='#141D27' />
          <path fillRule='evenodd' clipRule='evenodd' d='M9 5H5V9H9V5ZM3 3V11H11V3H3Z' fill='#141D27' />
          <path fillRule='evenodd' clipRule='evenodd' d='M9 15H5V19H9V15ZM3 13V21H11V13H3Z' fill='#141D27' />
        </svg>
      );
    }
    case 'layout': {
      return (
        <svg
          className={props.addClass}
          width='29'
          height='28'
          viewBox='0 0 29 28'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.4736 1L27.4736 27H25.4736L25.4736 1L27.4736 1Z'
            fill='#141D27'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.47363 1L3.47363 15H1.47363L1.47363 1L3.47363 1Z'
            fill='#141D27'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.47363 22L3.47363 25H1.47363L1.47363 22H3.47363Z'
            fill='#141D27'
          />
          <path fillRule='evenodd' clipRule='evenodd' d='M15.4736 3V5H13.4736V3H15.4736Z' fill='#141D27' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M15.4736 10L15.4736 22H13.4736L13.4736 10H15.4736Z'
            fill='#141D27'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.47363 1L27.4736 1L27.4736 3L3.47363 3L3.47363 1Z'
            fill='#141D27'
          />
          <path fillRule='evenodd' clipRule='evenodd' d='M15.4736 13H25.4736V15H15.4736V13Z' fill='#141D27' />
          <path fillRule='evenodd' clipRule='evenodd' d='M1.47363 25H25.4736V27H1.47363V25Z' fill='#141D27' />
        </svg>
      );
    }
    case 'heart': {
      return (
        <svg
          className={props.addClass}
          width='29'
          height='28'
          viewBox='0 0 29 28'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g opacity='0.15'>
            <path
              d='M25.7134 7.75568C25.3469 6.90703 24.8184 6.138 24.1575 5.49162C23.4962 4.84332 22.7164 4.32812 21.8606 3.97404C20.9733 3.60543 20.0215 3.41675 19.0606 3.41896C17.7126 3.41896 16.3974 3.78811 15.2544 4.48537C14.981 4.65217 14.7212 4.83537 14.4751 5.03498C14.229 4.83537 13.9692 4.65217 13.6958 4.48537C12.5528 3.78811 11.2376 3.41896 9.88955 3.41896C8.91885 3.41896 7.97822 3.6049 7.08955 3.97404C6.23096 4.32951 5.45713 4.84084 4.79268 5.49162C4.13095 6.13727 3.60234 6.90649 3.23682 7.75568C2.85674 8.63889 2.6626 9.57678 2.6626 10.542C2.6626 11.4526 2.84854 12.4014 3.21768 13.3666C3.52666 14.1733 3.96963 15.01 4.53564 15.8549C5.43252 17.192 6.66572 18.5865 8.19697 20.0002C10.7345 22.3436 13.2474 23.9623 13.354 24.028L14.0021 24.4436C14.2892 24.6268 14.6583 24.6268 14.9454 24.4436L15.5935 24.028C15.7001 23.9596 18.2103 22.3436 20.7505 20.0002C22.2817 18.5865 23.5149 17.192 24.4118 15.8549C24.9778 15.01 25.4235 14.1733 25.7298 13.3666C26.0989 12.4014 26.2849 11.4526 26.2849 10.542C26.2876 9.57678 26.0935 8.63889 25.7134 7.75568ZM14.4751 22.2807C14.4751 22.2807 4.74072 16.0436 4.74072 10.542C4.74072 7.75568 7.0458 5.49709 9.88955 5.49709C11.8884 5.49709 13.622 6.61271 14.4751 8.2424C15.3282 6.61271 17.0618 5.49709 19.0606 5.49709C21.9044 5.49709 24.2095 7.75568 24.2095 10.542C24.2095 16.0436 14.4751 22.2807 14.4751 22.2807Z'
              fill='#141D27'
            />
          </g>
        </svg>
      );
    }
    case 'arrow-sort': {
      return (
        // Добавил height т.к при любой выбранной сортировке
        // на убывание с последующим переключением на отображение списком стрелка увеличивается до x3 размеров
        <svg className={props.addClass} viewBox='0 0 17 16' fill='none' height='16' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M8.5841 3L8.34475 3L4.47363 6.80938L4.47363 8.41452L4.74368 8.41452L7.74789 5.4357L7.74789 12L9.18403 12L9.16868 5.4115L12.2189 8.41452L12.4736 8.41452L12.4736 6.80938L8.5841 3Z'
            fill='#F4571B'
          />
        </svg>
      );
    }
    case 'river-icon': {
      return (
        <svg
          className={props.addClass}
          width='33'
          height='32'
          viewBox='-1 -2 35 35'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect x='0.473633' width='32' height='32' rx='16' fill='#DFDEDA' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.0422 12.2223C14.4817 11.5645 14.7163 10.7911 14.7163 10H16.7163C16.7163 10.8805 17.0068 11.7365 17.5429 12.435C18.0789 13.1336 18.8305 13.6358 19.681 13.8637C20.5315 14.0916 21.4335 14.0325 22.247 13.6955C23.0605 13.3586 23.7401 12.7626 24.1804 12L25.9124 13C25.252 14.1439 24.2326 15.0378 23.0124 15.5433C21.7921 16.0487 20.4392 16.1374 19.1634 15.7956C17.8876 15.4537 16.7602 14.7004 15.9562 13.6526C15.8721 13.5431 15.7922 13.431 15.7163 13.3166C15.7126 13.3222 15.7088 13.3278 15.7051 13.3334C15.0458 14.3201 14.1087 15.0892 13.0124 15.5433C11.916 15.9974 10.7096 16.1162 9.54573 15.8847C8.38185 15.6532 7.31275 15.0818 6.47363 14.2426L7.88785 12.8284C8.44726 13.3878 9.15999 13.7688 9.93591 13.9231C10.7118 14.0775 11.5161 13.9983 12.247 13.6955C12.9779 13.3928 13.6026 12.8801 14.0422 12.2223ZM14.0422 18.2223C14.4817 17.5645 14.7163 16.7911 14.7163 16H16.7163C16.7163 16.8805 17.0068 17.7365 17.5429 18.435C18.0789 19.1336 18.8305 19.6358 19.681 19.8637C20.5315 20.0916 21.4335 20.0325 22.247 19.6955C23.0605 19.3586 23.7401 18.7626 24.1804 18L25.9124 19C25.252 20.1439 24.2326 21.0378 23.0124 21.5433C21.7921 22.0487 20.4392 22.1374 19.1634 21.7956C17.8876 21.4537 16.7602 20.7004 15.9562 19.6526C15.8721 19.5431 15.7922 19.431 15.7163 19.3166C15.7126 19.3222 15.7088 19.3278 15.7051 19.3334C15.0458 20.3201 14.1087 21.0892 13.0124 21.5433C11.916 21.9974 10.7096 22.1162 9.54573 21.8847C8.38185 21.6532 7.31275 21.0818 6.47363 20.2426L7.88785 18.8284C8.44726 19.3878 9.15999 19.7688 9.93591 19.9231C10.7118 20.0775 11.5161 19.9983 12.247 19.6955C12.9779 19.3928 13.6026 18.8801 14.0422 18.2223Z'
            fill='white'
          />
        </svg>
      );
    }
    case 'star-icon': {
      return (
        <svg
          className={props.addClass}
          width='33'
          height='32'
          viewBox='-1 -2 35 35'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect x='0.473633' width='32' height='32' rx='16' fill='#DFDEDA' />
          <path
            d='M16.7123 9.18832L18.7463 13.1474L18.9773 13.5969L19.4761 13.6776L23.87 14.3886L20.7332 17.5465L20.377 17.905L20.4544 18.4044L21.136 22.8029L17.1634 20.7955L16.7123 20.5676L16.2613 20.7955L12.2887 22.8029L12.9703 18.4044L13.0476 17.905L12.6915 17.5465L9.55473 14.3886L13.9486 13.6776L14.4474 13.5969L14.6784 13.1474L16.7123 9.18832Z'
            stroke='white'
            strokeWidth='2'
          />
        </svg>
      );
    }
    case 'loggia-icon': {
      return (
        <svg
          className={props.addClass}
          width='32'
          height='32'
          viewBox='-1 -2 35 35'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='16' cy='16' r='16' fill='#DFDEDA' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.0056 6.98102L9.48444 12.7413H22.5268L16.0056 6.98102ZM25.5479 12.7413L16.0056 4.3125L6.46342 12.7413L4.26566 14.6826L4.21971 14.7052L4.22588 14.7177L4.19922 14.7413H4.23746L5.68448 17.6856L5.95917 18.2446H6.58195H10.6971H11.1307L11.4271 17.928L12.5064 16.7751L13.5857 17.928L13.8821 18.2446H14.3158H15V24.6738H12.0049V26.6738H19.9941V24.6738H17V18.2446H18.1416H18.5752L18.8716 17.928L19.9509 16.7751L21.0302 17.928L21.3266 18.2446H21.7603H24.9864H25.5298L25.8254 17.7887L27.8016 14.7413H27.8121L27.8054 14.7354L27.8117 14.7256L27.7461 14.683L25.5479 12.7413ZM7.20474 16.2446L6.46594 14.7413H7.22024H11.6707L10.2634 16.2446H7.20474ZM14.7494 16.2446L13.3421 14.7413H19.1152L17.7079 16.2446H14.7494ZM22.1939 16.2446L20.7866 14.7413H24.791H25.4179L24.443 16.2446H22.1939ZM25.9849 22.8829L26.9316 19.3497L24.9998 18.832L24.0337 22.4376H22.7676H20.7686H20.7676V26.6743H22.7676V24.4376H23.9893V26.6739H25.9893V22.8829H25.9849ZM4.84082 19.3497L5.78754 22.8829H5.7832L5.7832 26.6739H7.7832L7.7832 24.4376H9.00488V26.6743H11.0049V22.4376H11.0039H9.00488H7.73877L6.77267 18.832L4.84082 19.3497Z'
            fill='white'
          />
        </svg>
      );
    }
    case 'balcony-icon': {
      return (
        <svg
          className={props.addClass}
          width='32'
          height='32'
          viewBox='-1 -2 35 35'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='16' cy='16' r='16' fill='#DFDEDA' />
          <path d='M7 18H25V26H7V18Z' stroke='white' strokeWidth='2' />
          <rect x='10.6807' y='5' width='10.6387' height='13' stroke='white' strokeWidth='2' />
          <rect x='16' y='4.59375' width='2' height='12.4062' fill='white' />
          <rect x='22' y='9' width='2' height='12' transform='rotate(90 22 9)' fill='white' />
          <rect x='10.543' y='17' width='2' height='10' fill='white' />
          <rect x='15' y='17' width='2' height='10' fill='white' />
          <rect x='19.457' y='17' width='2' height='10' fill='white' />
        </svg>
      );
    }
    case 'north': {
      return (
        <svg className={props.addClass} viewBox='-2 -2 57 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_5988_19450)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M48.1249 14.0121C45.3182 9.48534 41.0854 6.02085 36.0932 4.16426C31.101 2.30767 25.633 2.16449 20.5505 3.75726L19.9524 1.84878C25.4585 0.123277 31.3821 0.278395 36.7903 2.2897C42.1986 4.301 46.7841 8.0542 49.8247 12.9582C52.8653 17.8622 54.1882 23.6383 53.585 29.3768C52.9819 35.1153 50.487 40.4901 46.4932 44.6547C42.4995 48.8194 37.2338 51.5372 31.5256 52.3801C25.8174 53.223 19.991 52.1432 14.964 49.3106C9.93696 46.478 5.99503 42.0537 3.75902 36.7344C1.52301 31.4152 1.11997 25.5032 2.61339 19.9297L4.54524 20.4474C3.1667 25.5921 3.53873 31.0493 5.60274 35.9594C7.66675 40.8695 11.3055 44.9535 15.9458 47.5682C20.5861 50.1828 25.9643 51.1796 31.2335 50.4016C36.5026 49.6235 41.3632 47.1147 45.0497 43.2705C48.7362 39.4262 51.0392 34.4648 51.596 29.1677C52.1527 23.8706 50.9316 18.5388 48.1249 14.0121Z'
              fill='#141D27'
            />
            <path
              d='M22.3152 22.1718L22.548 21.939L29.8765 21.737L31.3882 23.2487L31.13 23.5069L25.409 23.6329L33.031 31.2549L31.6342 32.6518L24.0193 25.0369L23.8663 30.7847L23.6171 31.034L22.0983 29.5152L22.3152 22.1718Z'
              fill='#141D27'
            />
            <path d='M17.832 6V16.496H15.176L9.672 10V16.496H7V6H9.656L15.16 12.48V6H17.832Z' fill='#141D27' />
          </g>
          <defs>
            <clipPath id='clip0_5988_19450'>
              <rect width='54' height='54' fill='white' />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case 'filter-icon': {
      return (
        <svg
          className={props.addClass}
          width='54'
          height='54'
          viewBox='-2 -2 58 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='27' cy='27' r='27' fill='#141D27' />
          <rect x='16' y='19' width='22' height='2' fill='white' />
          <rect x='16' y='26' width='22' height='2' fill='white' />
          <rect x='19.75' y='17.75' width='1.5' height='4.5' fill='#141D27' stroke='white' strokeWidth='1.5' />
          <rect x='16' y='33' width='22' height='2' fill='white' />
          <rect x='21.75' y='31.75' width='1.5' height='4.5' fill='#141D27' stroke='white' strokeWidth='1.5' />
          <rect x='31.75' y='24.75' width='1.5' height='4.5' fill='#141D27' stroke='white' strokeWidth='1.5' />
        </svg>
      );
    }
    case 'download': {
      return (
        <svg className={props.addClass} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M12.6795 17H12.2906L6 10.651L6 7.97579H6.43882L11.3207 12.9405L11.3207 2L13.6544 2L13.6295 12.9808L18.5861 7.9758H19V10.651L12.6795 17Z'
            fill='#A68760'
          />
          <path d='M6 21L6 19H19V21H6Z' fill='#A68760' />
        </svg>
      );
    }
    case 'share': {
      return (
        <svg className={props.addClass} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 5.15007 15.011 5.29756 15.0323 5.44171L9.88762 9.23202C9.15959 8.47272 8.135 8 7 8C4.79086 8 3 9.79086 3 12C3 14.2091 4.79086 16 7 16C7.9845 16 8.88593 15.6443 9.5828 15.0545L15.0238 18.62C15.0081 18.7445 15 18.8713 15 19C15 20.6569 16.3431 22 18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C17.1992 16 16.4717 16.3138 15.9337 16.8251L10.74 13.4216C10.908 12.9799 11 12.5007 11 12C11 11.6515 10.9554 11.3135 10.8717 10.9912L15.9859 7.22336C16.5183 7.70598 17.2248 8 18 8ZM18 6C18.5523 6 19 5.55228 19 5C19 4.44772 18.5523 4 18 4C17.4477 4 17 4.44772 17 5C17 5.55228 17.4477 6 18 6ZM18 20C18.5523 20 19 19.5523 19 19C19 18.4477 18.5523 18 18 18C17.4477 18 17 18.4477 17 19C17 19.5523 17.4477 20 18 20ZM9 12C9 13.1046 8.10457 14 7 14C5.89543 14 5 13.1046 5 12C5 10.8954 5.89543 10 7 10C8.10457 10 9 10.8954 9 12Z'
            fill='#A68760'
          />
        </svg>
      );
    }
    case 'vk': {
      return (
        <svg className={props.addClass} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_2922_7995)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M20.4555 8.17376C20.5719 7.79063 20.4555 7.50854 19.8976 7.50854H18.0556C17.5869 7.50854 17.3708 7.75204 17.2536 8.02079C17.2536 8.02079 16.3168 10.2634 14.9899 11.7202C14.5604 12.1426 14.3654 12.2766 14.131 12.2766C14.0138 12.2766 13.8377 12.1426 13.8377 11.7588V8.17376C13.8377 7.71344 13.7086 7.50854 13.3184 7.50854H10.4218C10.1292 7.50854 9.95305 7.72186 9.95305 7.92466C9.95305 8.36041 10.6169 8.46146 10.6849 9.68804V12.3531C10.6849 12.9376 10.5776 13.0436 10.3432 13.0436C9.71868 13.0436 8.19949 10.7904 7.2978 8.21235C7.12307 7.71064 6.94624 7.50854 6.4754 7.50854H4.63202C4.10574 7.50854 4.00049 7.75204 4.00049 8.02079C4.00049 8.49935 4.62501 10.8767 6.90905 14.0211C8.43175 16.1683 10.5755 17.3324 12.5283 17.3324C13.6994 17.3324 13.844 17.0742 13.844 16.6286V15.0056C13.844 14.4884 13.9549 14.3853 14.3261 14.3853C14.5997 14.3853 15.0678 14.52 16.161 15.555C17.4101 16.7823 17.6157 17.3324 18.3188 17.3324H20.1607C20.687 17.3324 20.9509 17.0742 20.7993 16.5633C20.6323 16.0553 20.0358 15.3178 19.245 14.4428C18.8156 13.9446 18.1714 13.4078 17.9756 13.139C17.7027 12.7945 17.7806 12.6408 17.9756 12.3342C17.9756 12.3342 20.2211 9.22842 20.4548 8.17376H20.4555Z'
              fill='white'
            />
          </g>
          <defs>
            <clipPath id='clip0_2922_7995'>
              <rect width='16.8409' height='16.8409' fill='white' transform='translate(4 4)' />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case 'telegram': {
      return (
        <svg
          className={props.addClass}
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_2922_7985)'>
            <path
              d='M5.43578 11.24C9.33135 9.54313 11.9284 8.42435 13.2269 7.88385C16.9388 6.34045 17.7091 6.07239 18.2122 6.06331C18.3229 6.06154 18.5692 6.08889 18.73 6.21884C18.8636 6.32844 18.9012 6.47667 18.92 6.58074C18.9367 6.6847 18.9596 6.92165 18.9408 7.10662C18.7404 9.21933 17.8699 14.3462 17.4273 16.7126C17.2415 17.7138 16.872 18.0495 16.515 18.0823C15.7384 18.1537 15.1497 17.5695 14.3981 17.0771C13.2227 16.3061 12.5589 15.8264 11.4169 15.0742C10.0975 14.2049 10.9535 13.727 11.705 12.9462C11.9013 12.7418 15.3208 9.63227 15.3856 9.35022C15.3939 9.31494 15.4023 9.18342 15.3229 9.11411C15.2457 9.04459 15.1309 9.06839 15.0474 9.08718C14.9284 9.1139 13.0516 10.3556 9.41068 12.8122C8.87832 13.1784 8.39608 13.3569 7.96184 13.3475C7.48586 13.3372 6.56729 13.0777 5.88462 12.856C5.04956 12.584 4.3836 12.4402 4.44205 11.9782C4.47128 11.7377 4.80322 11.4915 5.43578 11.24Z'
              fill='#141D27'
            />
          </g>
          <defs>
            <clipPath id='clip0_2922_7985'>
              <rect width='19.5' height='19.5' fill='white' transform='translate(2 2)' />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case 'copy': {
      return (
        <svg className={props.addClass} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M13.1052 8.42017H5.50776C4.66977 8.42017 3.98828 9.10165 3.98828 9.93965V17.537C3.98828 18.375 4.66977 19.0565 5.50776 19.0565H13.1052C13.9432 19.0565 14.6246 18.375 14.6246 17.537V9.93965C14.6246 9.10165 13.9432 8.42017 13.1052 8.42017Z'
            fill='#141D27'
          />
          <path
            d='M17.5378 3.98828H9.94038C9.53739 3.98828 9.1509 4.14837 8.86594 4.43333C8.58099 4.71828 8.4209 5.10477 8.4209 5.50776V7.02724H14.4988C14.9018 7.02724 15.2883 7.18733 15.5733 7.47229C15.8582 7.75725 16.0183 8.14373 16.0183 8.54672V14.6246H17.5378C17.9408 14.6246 18.3273 14.4646 18.6122 14.1796C18.8972 13.8946 19.0573 13.5082 19.0573 13.1052V5.50776C19.0573 5.10477 18.8972 4.71828 18.6122 4.43333C18.3273 4.14837 17.9408 3.98828 17.5378 3.98828Z'
            fill='#141D27'
          />
        </svg>
      );
    }
    case 'copied': {
      return (
        <svg
          className={props.addClass}
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M7 10.625L11.2308 15L18 8' stroke='#F4571B' strokeWidth='2' />
        </svg>
      );
    }
    case 'view': {
      return (
        <svg
          className={props.addClass}
          width='48'
          height='48'
          viewBox='0 0 48 48'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='48' height='48' rx='24' fill='none' />
          <path
            className='stroke'
            opacity='0.15'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
            fill='#141D27'
          />
          <path
            d='M37.9934 23.1735C36.4626 21.2672 30.9463 15 24.1591 15C17.3719 15 11.8556 21.2673 10.3249 23.1735C9.89171 23.7223 9.89171 24.5021 10.3249 25.0507C11.8556 26.9859 17.3719 33.253 24.1591 33.253C30.9463 33.253 36.4626 26.9857 37.9934 25.0796C38.3977 24.5308 38.3977 23.7223 37.9934 23.1735ZM24.1591 31.3469C18.3828 31.3469 13.3863 25.7728 12 24.1266C13.3286 22.4514 18.3828 16.9062 24.1591 16.9062C29.9354 16.9062 34.9319 22.4803 36.3182 24.1266C34.9608 25.8017 29.9354 31.3469 24.1591 31.3469Z'
            fill='#141D27'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.5547 24.1266C18.5547 21.0362 21.0673 18.5236 24.1577 18.5236C25.421 18.5236 26.5878 18.9435 27.5259 19.6511L26.4137 21.1839C25.7867 20.7102 25.0042 20.4297 24.1577 20.4297C22.107 20.4297 20.4609 22.1049 20.4609 24.1266C20.4609 26.1483 22.107 27.8234 24.1577 27.8234C26.2083 27.8234 27.8833 26.1773 27.8833 24.1266C27.8833 23.868 27.8567 23.6159 27.806 23.3727L29.6142 22.8504C29.71 23.2605 29.7607 23.6877 29.7607 24.1266C29.7607 27.217 27.2481 29.7296 24.1577 29.7296C21.0673 29.7296 18.5547 27.217 18.5547 24.1266Z'
            fill='#141D27'
          />
        </svg>
      );
    }
    case 'view-plus': {
      return (
        <svg className={props.addClass} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect width='48' height='48' rx='24' fill='#F4571B' />
          <path d='M23 17H25V31H23V17Z' fill='white' />
          <path d='M17 25L17 23L31 23V25L17 25Z' fill='white' />
        </svg>
      );
    }
    case 'arrow-next': {
      return (
        <svg
          width='54'
          height='54'
          className={props.addClass}
          viewBox='0 0 54 54'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='54' height='54' rx='27' fill='white' />
          <path
            d='M37 26.8599V27.1593L32.1725 32H30.1245V31.6681L33.9145 27.9094H18V26.1136H33.9049L30.1149 22.3204V22H32.1725L37 26.8599Z'
            fill='#141D27'
          />
        </svg>
      );
    }
    case 'arrow-prev': {
      return (
        <svg
          width='54'
          height='54'
          className={props.addClass}
          viewBox='0 0 54 54'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='54' height='54' rx='27' fill='white' />
          <path
            d='M17 26.8599V27.1593L21.8275 32H23.8755V31.6681L20.0855 27.9094H36V26.1136H20.0951L23.8851 22.3204V22H21.8275L17 26.8599Z'
            fill='#141D27'
          />
        </svg>
      );
    }
    case 'mail-share': {
      return (
        <svg className={props.addClass} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g clipPath='url(#clip0_6251_19575)'>
            <path d='M11.4505 11.9849L21.7305 5.50486' stroke='#A68760' strokeWidth='2' strokeMiterlimit='10' />
            <path d='M12.5508 11.9849L2.27078 5.50486' stroke='#A68760' strokeWidth='2' strokeMiterlimit='10' />
            <path d='M1.27047 18.605L22.7305 18.605' stroke='#A68760' strokeWidth='2' strokeMiterlimit='10' />
            <path
              d='M21.2621 19.4426L22.6719 18.0239L14.0536 9.45961L12.6438 10.8783L21.2621 19.4426Z'
              fill='#A68760'
            />
            <path d='M2.68645 19.4416L11.3018 10.8743L9.8915 9.45611L1.27619 18.0234L2.68645 19.4416Z' fill='#A68760' />
            <path
              d='M2.55047 19.5749L4.56047 17.5749L3.27047 17.5749L3.27047 6.39495L20.7305 6.39495L20.7305 17.575L19.3805 17.575L21.4005 19.575L22.7305 19.575L22.7305 4.39495L1.27047 4.39495L1.27047 19.5749L2.55047 19.5749Z'
              fill='#A68760'
            />
          </g>
          <defs>
            <clipPath id='clip0_6251_19575'>
              <rect width='21.46' height='15.21' fill='white' transform='translate(22.7305 19.605) rotate(-180)' />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case 'info': {
      return (
        <svg
          className={props.addClass}
          width='21'
          height='22'
          viewBox='0 0 21 22'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect y='0.5' width='21' height='21' rx='10.5' fill='#DFDEDA' />
          <path
            d='M11.552 6.004L11.44 13.028H9.104L8.992 6.004H11.552ZM10.272 16.708C9.83467 16.708 9.47733 16.5747 9.2 16.308C8.92267 16.0307 8.784 15.6733 8.784 15.236C8.784 14.8093 8.92267 14.4627 9.2 14.196C9.47733 13.9187 9.83467 13.78 10.272 13.78C10.7093 13.78 11.0667 13.9187 11.344 14.196C11.6213 14.4627 11.76 14.8093 11.76 15.236C11.76 15.6733 11.6213 16.0307 11.344 16.308C11.0667 16.5747 10.7093 16.708 10.272 16.708Z'
            fill='#A68760'
          />
        </svg>
      );
    }
    case 'arrow-orange': {
      return (
        <svg width='19' height='10' viewBox='0 0 19 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M-8.98654e-07 5.14006L-9.24821e-07 4.84075L4.82747 -6.9889e-07L6.87549 -8.77933e-07L6.87549 0.331922L3.0855 4.09056L19 4.09056L19 5.88641L3.09513 5.88642L6.88512 9.67958L6.88512 10L4.82747 10L-8.98654e-07 5.14006Z'
            fill='#F4571B'
          />
        </svg>
      );
    }
    case 'arrow-progress': {
      return (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={props.addClass}
        >
          <path d='M9 18L15 12L9 6' stroke='#141D27' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
      );
    }
    default: {
      break;
    }
  }
};

export default CatalogPageSvg;
