import { createSlice } from '@reduxjs/toolkit';
import changeStudioValue from '../tools/_change-studio-value';
import sortNumber from '../tools/_sort-number';

const initialState = {
  favoriteList: [],
  showedList: [],
  //Колонки таблицы для сортировки
  sortColumns: {
    number: 'Номер',
    rooms: 'Спальни',
    area: 'Площадь',
    section_number: 'Корпус',
    floor: 'Этаж',
    advantages: '',
    price: 'Стоимость',
    fav: '',
    scheme: '',
  },
  //Текущий параметр сортировки
  sortParameters: {
    value: 'price',
    direction: 'asc_',
  },
  //Места вызова функции
  //по клику на заголовок столбца таблицы
  inTableHead: 'inTableHead',
  //после изменения состояния формы
  inForm: 'inForm',
};

const refreshLocalStorage = favoriteList => {
  localStorage.setItem('favoriteList', JSON.stringify(favoriteList));
};

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    resetList: state => {
      state.favoriteList = [];
      state.showedList = [];
      refreshLocalStorage(state.favoriteList);
    },
    removeApartmentById: (state, action) => {
      state.favoriteList = state.favoriteList.filter(apartment => apartment.id !== action.payload);
      refreshLocalStorage(state.favoriteList);
    },
    addApartmentToFavorites: (state, action) => {
      state.favoriteList = [...state.favoriteList, action.payload];

      if (!state.favoriteList.find(item => item.id === action.payload.id)) {
        state.showedList = [...state.showedList, action.payload];
      }

      refreshLocalStorage(state.favoriteList);
    },
    refreshFavoriteList: (state, action) => {
      state.favoriteList = action.payload;
      state.showedList = action.payload;
      refreshLocalStorage(action.payload);
    },

    sortFavoriteList: (state, action) => {
      if (action.payload.includes('asc-') || action.payload.includes('dec-')) {
        const direction = action.payload.slice(0, 3) + '_';
        const params = action.payload.replace('asc-', '').replace('dec-', '');
        if (params === 'rooms') {
          const array = state.showedList.map(flat => changeStudioValue(flat, 'studio', '0'));
          sortNumber(array, 'rooms', direction);
          state.showedList = array.map(flat => changeStudioValue(flat, '0', 'studio'));
        } else {
          sortNumber(state.showedList, params, direction);
        }

        state.sortParameters = {
          value: params,
          direction,
        };
      } else {
        const withWhiteBox = state.showedList.filter(item => item.attributes.whiteBox === true);
        const withoutWhiteBox = state.showedList.filter(item => item.attributes.unfinished === true);

        if (action.payload.includes('with-')) {
          state.showedList = withWhiteBox.concat(withoutWhiteBox);
        } else {
          state.showedList = withoutWhiteBox.concat(withWhiteBox);
        }
      }
    },

    refreshSortFavoriteList: state => {
      const directionAndValue =
        state.sortParameters.direction.replace('_', '-') + state.sortParameters.value.replace('price', 'currentPrice');
      favoritesSlice.caseReducers.sortFavoriteList(state, { payload: directionAndValue });
    },

    setShowedList: (state, action) => {
      state.showedList = action.payload;
    },
    sortByTableFlats(state, action) {
      // Исключаем фильтр по характеристикам
      if (action.payload.value !== 'advantages') {
        let direction = state.sortParameters.direction;

        if (action.payload.placeCall === state.inTableHead) {
          //Если выхов произошел по клику на заголовок таблицы
          //переключить направления сортировки
          if (direction === 'asc_') {
            direction = 'dec_';
          } else {
            direction = 'asc_';
          }
        }

        state.sortParameters = {
          value: action.payload.value,
          direction,
        };

        if (action.payload.value === 'rooms') {
          const array = state[action.payload.array].map(flat => changeStudioValue(flat, 'studio', '0'));
          sortNumber(array, state.sortParameters.value, state.sortParameters.direction);
          state[action.payload.array] = array.map(flat => changeStudioValue(flat, '0', 'studio'));
        } else if (action.payload.value === 'section_number') {
          if (state.sortParameters.direction === 'asc_') {
            state[action.payload.array].sort((a, b) => a.section.number - b.section.number);
          } else {
            state[action.payload.array].sort((a, b) => b.section.number - a.section.number);
          }
        } else if (action.payload.value === 'price') {
          sortNumber(state[action.payload.array], 'currentPrice', state.sortParameters.direction);
        } else {
          sortNumber(state[action.payload.array], state.sortParameters.value, state.sortParameters.direction);
        }
      }
    },
  },
});

export const {
  addApartmentToFavorites,
  removeApartmentById,
  resetList,
  refreshFavoriteList,
  sortFavoriteList,
  setShowedList,
  sortByTableFlats,
  refreshSortFavoriteList,
} = favoritesSlice.actions;

export default favoritesSlice.reducer;
