import { combineReducers, configureStore } from '@reduxjs/toolkit';
import catalogSlice from './catalogSlice';
import componentsSlice from './componentsSlice';
import favoriteSlice from './favoriteSlice';
import flatSlice from './flatSlice';
import gallerySlice from './gallerySlice';
import mainSlice from './mainSlice';
import parkingSlice from './parkingSlice';

const reducers = combineReducers({
  parking: parkingSlice,
  favoritePage: favoriteSlice,
  catalogPage: catalogSlice,
  flatPage: flatSlice,
  componentsState: componentsSlice,
  gallerySlice,
  main: mainSlice,
});

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  devTools: false,
});

export default store;
