import { Helmet } from 'react-helmet-async';

const HelmetSample = ({
  title = 'Portland – новый жилой комплекс в акватории Южного речного порта от застройщика Forma.',
  description = 'Portland - жилой комплекс бизнес-класса от Forma на берегу Москвы-реки в акватории Южного речного порта — динамично развивающегося и трансформирующегося района столицы. Новая точка притяжения для смелых и ярких личностей.',
  img = `${process.env.PUBLIC_URL}/badge.jpg`,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:site_name' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='628' />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='vk:title' content={title} />
      <meta name='vk:description' content={description} />
      <meta property='og:image' content={img} />
    </Helmet>
  );
};

export default HelmetSample;
