import './GallerySlider.scss';
import 'swiper/css';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { handleOpen } from '../../../state/componentsSlice';
import { changeActiveSlide } from '../../../state/gallerySlice';
import { enableBodyScroll } from '../../../tools/_body-scroll-lock';
import CatalogPageSvg from '../../svgs/CatalogPageSvg';
import ContactsPageSvg from '../../svgs/ContactsPageSvg';
import MainPageSvg from '../../svgs/MainPageSvg';

const GallerySlider = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const photos = useSelector(state => state.gallerySlice.activePhotoPack);
  const openGallerySlider = useSelector(state => state.componentsState.openGallerySlider);
  const activeSlide = useSelector(state => state.gallerySlice.activeSlide);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [heightSlider, setHeightSlider] = useState(110);
  const [show, setShow] = useState(true);
  const [showSlide, setShowSlide] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [bigImagesStyles, setBigImagesStyles] = useState({});
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

  const dispatch = useDispatch();

  const handleCalculateImageStyles = (imageUrl, index) => {
    const sliderHeight = height - heightSlider;
    const img = new Image();
    img.src = imageUrl;
    img.addEventListener('load', () => {
      const imgWidth = img.width || 1;
      const imgHeight = img.height || 1;
      const imgSizesIndex = imgWidth / imgHeight;
      const heightIndex = sliderHeight / imgHeight;
      const widthIndex = width / imgWidth;
      if (imgSizesIndex >= 0.8 && imgSizesIndex <= 1.2) {
        if (isLandscape) {
          setBigImagesStyles(prev => ({ ...prev, [index]: { height: sliderHeight, width: imgWidth * heightIndex } }));
        } else {
          setBigImagesStyles(prev => ({ ...prev, [index]: { width, height: imgHeight * widthIndex } }));
        }
      } else if (imgSizesIndex < 0.8) {
        if (heightIndex <= widthIndex) {
          setBigImagesStyles(prev => ({ ...prev, [index]: { width, height: imgHeight * widthIndex } }));
        } else {
          setBigImagesStyles(prev => ({ ...prev, [index]: { height: sliderHeight, width: imgWidth * heightIndex } }));
        }
      } else if (imgSizesIndex > 1.2) {
        if (heightIndex >= widthIndex) {
          setBigImagesStyles(prev => ({ ...prev, [index]: { width, height: imgHeight * widthIndex } }));
        } else {
          setBigImagesStyles(prev => ({ ...prev, [index]: { height: sliderHeight, width: imgWidth * heightIndex } }));
        }
      }
    });
  };

  useEffect(() => {
    if (openGallerySlider && photos?.absolutePath.length > 0) {
      photos?.absolutePath.forEach((photo, index) => handleCalculateImageStyles(photo, index));
    }
  }, [openGallerySlider, photos, width, height]);

  useEffect(() => {
    const resizeCallback = () =>
      photos?.absolutePath.forEach((photo, index) => handleCalculateImageStyles(photo, index));
    window.addEventListener('resize', resizeCallback);

    return () => window.removeEventListener('resize', resizeCallback);
  }, []);

  //Управление модальным окном
  const hideModal = () => {
    setBigImagesStyles({});
    dispatch(handleOpen({ page: 'gallery-slider', value: false }));
    enableBodyScroll();
  };

  const changeShowIcon = () => {
    setShow(false);
    setTimeout(() => setShow(true), 100);
  };

  useEffect(() => {
    if (width <= 541) {
      setHeightSlider(80);
    }
  }, [width, setHeightSlider]);

  useEffect(() => {
    if (!openGallerySlider) {
      setShowSlide(false);
    } else {
      swiper?.slideTo(activeSlide);
      setShowSlide(true);
      dispatch(changeActiveSlide(0));
    }
  }, [openGallerySlider]);

  //  Создание слайдов для главного слайдера
  const bigSliderElements = useMemo(() => {
    if (Object.keys(bigImagesStyles).length === photos?.absolutePath.length) {
      return photos?.absolutePath.map((path, index) => {
        const imgStyles = bigImagesStyles[index];
        if (!imgStyles) return;
        const { width: imgWidth, height: imgHeight } = imgStyles;
        let newY = 0;
        let newX = 0;
        if (imgHeight === height - heightSlider) {
          newX = (width - imgWidth) / 2;
        } else if (imgWidth === width) {
          newY = (height - heightSlider - imgHeight) / 2;
        }
        return (
          <SwiperSlide key={`bigSlide-${photos?._id}-${index}`} tag='li'>
            <div className='GallerySlider__slide-big'>
              <TransformWrapper wheel={{ wheelDisabled: true }}>
                {({ zoomIn, zoomOut }) => (
                  <React.Fragment>
                    <div className={`GallerySlider__zoom ${show ? 'show' : 'hide'}`}>
                      <button className='GallerySlider__arrow zoom__plus swiper-no-swiping' onClick={() => zoomIn()}>
                        <ContactsPageSvg id='plus' />
                      </button>
                      <button className='GallerySlider__arrow zoom_minus swiper-no-swiping' onClick={() => zoomOut()}>
                        <ContactsPageSvg id='minus' />
                      </button>
                    </div>
                    <TransformComponent>
                      <div
                        style={{ margin: `${newY}px ${newX}px`, width: imgWidth, height: imgHeight }}
                        className={`GallerySlider__slide-wrapper swiper-no-swiping ${showSlide ? 'show' : ''}`}
                      >
                        <img src={photos?.absolutePath[index]} alt='' className='GallerySlider__slide-img' />
                      </div>
                    </TransformComponent>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </div>
          </SwiperSlide>
        );
      });
    }
    return [];
  }, [bigImagesStyles]);

  //  Создание слайдов для детализированного слайдера
  const mainSliderElements = useMemo(() => {
    return photos?.absolutePath?.map((path, index) => {
      return (
        <SwiperSlide key={`slide-${photos?._id}-${index}`} tag='li' className='GallerySlider__slide-min'>
          <img src={path} alt='' className='GallerySlider__slide-min-img' />
        </SwiperSlide>
      );
    });
  }, [bigImagesStyles]);

  return (
    <div className={`GallerySlider ${openGallerySlider ? 'show' : ''}`} style={{ height: `${height}px` }}>
      <span className='GallerySlider__closer' onClick={() => hideModal()}>
        <MainPageSvg id='close-circle' />
      </span>
      <Swiper
        className='GallerySlider__big'
        modules={[Navigation, Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        slidesPerView={1}
        tag='section'
        wrapperTag='ul'
        noSwipingClass='swiper-no-swiping'
        watchSlidesProgress={true}
        navigation={{
          prevEl: '.bigSlider__prev',
          nextEl: '.bigSlider__next',
        }}
        style={{ height: `${height}px` }}
        onSlideChange={swiper => {
          changeShowIcon();
          thumbsSwiper?.slideTo(swiper.activeIndex);
        }}
        onSwiper={swiper1 => setSwiper(swiper1)}
      >
        {bigSliderElements}
        <div className='GallerySlider__arrows swiper-no-swiping'>
          <button className='GallerySlider__arrow bigSlider__prev swiper-no-swiping'>
            <CatalogPageSvg id='arrow-prev' />
          </button>
          <button className='GallerySlider__arrow bigSlider__next swiper-no-swiping'>
            <CatalogPageSvg id='arrow-next' />
          </button>
        </div>
      </Swiper>
      <div className='GallerySlider-wrapper__min'>
        <Swiper
          id='thumbs'
          modules={[Navigation, Thumbs]}
          onSwiper={setThumbsSwiper}
          tag='section'
          wrapperTag='ul'
          className='GallerySlider__min'
          slidesPerView={8}
          spaceBetween={isMobile ? 8 : 15}
          breakpointsBase='container'
          centeredSlides={true}
          centeredSlidesBounds={true}
          breakpoints={{
            220: { slidesPerView: 3.3 },
            540: { slidesPerView: 4.5 },
            1023: { slidesPerView: 7.5 },
          }}
          style={{ height: heightSlider }}
        >
          {mainSliderElements}
        </Swiper>
      </div>
    </div>
  );
};

export default GallerySlider;
