import './ServerErrorPage.scss';
import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import MainHeader from '../../components/header/main-header/MainHeader';
import ROUTES from '../../constants/routes';

const ServerErrorPage = () => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const timeline = gsap.timeline({
      delay: 1, // Set an initial delay for the entire timeline
    });

    const delta = isMobileOnly ? 1.4 : 1.8;

    timeline
      .fromTo(
        [firstRef.current, secondRef.current, thirdRef.current],
        {
          delay: 2,
          opacity: 1,
          y: -1000,
        },
        {
          opacity: 1,
          y: 0,
          stagger: 0.1,
          duration: 1.2,
          ease: `back.out(${delta})`,
        },
      )
      .fromTo(
        textRef.current,
        { opacity: 0, y: -200 },
        { opacity: 1, y: 0, ease: `back.out(2))`, duration: 0.9 },
        '-=1.2',
      );

    return () => {
      timeline && timeline.kill();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>500</title>
      </Helmet>
      <MainHeader />
      <div className='ServerErrorPage' data-status-code='500'>
        <div className='ServerErrorPage__content'>
          <div className='ServerErrorPage__error-wrapper'>
            <div ref={firstRef} className='ServerErrorPage__title'>
              {' '}
              5{' '}
            </div>
            <div ref={secondRef} className='ServerErrorPage__title'>
              {' '}
              0{' '}
            </div>
            <div ref={thirdRef} className='ServerErrorPage__title'>
              {' '}
              0{' '}
            </div>
          </div>
          <div ref={textRef} className='ServerErrorPage__text-wrapper'>
            <p className='ServerErrorPage__text'>
              Внутренняя ошибка сервера. Попробуйте обновить страницу через некоторое время.
            </p>
            <NavLink to={`${ROUTES.root}`} className='ServerErrorPage__link'>
              <span>Вернуться на главную</span>
              <svg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M15 6.32048V6.70943L8.65103 13H5.97579V12.5612L10.9405 7.67933H0V5.34561L10.9808 5.37054L5.97579 0.413886V0H8.65103L15 6.32048Z'
                  fill='#F4571B'
                />
              </svg>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServerErrorPage;
