import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import store from './state/reduxStore';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

//Запись в хранилище ширины экрана пользователя при её изменении
// window.addEventListener('resize', () => {
//   store.dispatch(handleResizeWindow({ width: window.innerWidth, height: window.innerHeight }));
// });

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </Provider>,
);
