import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openModelForm: false,
  modalFormType: null,
  openFavoritesForm: false,
  openFlatForm: false,
  openGallerySlider: false,
  openBurgerMenu: false,
  openedAccordion: {
    catalogFormOther: false,
    catalogFormGeneral: false,
    catalogFormModal: true,
  },
  scrollPosition: typeof window === 'object' ? window.scrollY : null,

  isVisibleMainHero: false,
  isVisibleMainPanorama: false,
  isVisibleMainRender: false,
  isVisibleCatalogFormButton: false,

  showMessageDone: false,
  showMessageError: false,

  openMenuMobile: false,
  openPanoramaModel: false,
  visibleScroll: false,
  isEqualizerOpen: false,
  isInstallmentModalOpen: false,
};

export const ComponentsSlice = createSlice({
  name: 'componentsState',
  initialState,
  reducers: {
    handleOpen: (state, action) => {
      switch (action.payload.page) {
        case 'burger-menu':
          state.openBurgerMenu = action.payload.value;
          break;
        case 'modal-form':
          state.modalFormType = action.payload.modalFormType || 'orderCallModalForm';
          state.openModelForm = action.payload.value;
          break;
        case 'open-favorites-form':
          state.openFavoritesForm = action.payload.value;
          break;
        case 'open-flat-form':
          state.openFlatForm = action.payload.value;
          break;
        case 'show-message-done':
          state.showMessageDone = action.payload.value;
          break;
        case 'show-message-error':
          state.showMessageError = action.payload.value;
          break;
        case 'gallery-slider':
          state.openGallerySlider = action.payload.value;
          break;
        case 'equalizer':
          state.isEqualizerOpen = action.payload.value;
          break;
        case 'installment-modal-open':
          state.isInstallmentModalOpen = action.payload.value;
          break;
        default:
          break;
      }
    },
    setScrollPosition(state, action) {
      state.scrollPosition = action.payload;
    },
    handleVisible: (state, action) => {
      switch (action.payload.section) {
        case 'main-hero':
          state.isVisibleMainHero = action.payload.value;
          break;
        case 'main-panorama':
          state.isVisibleMainPanorama = action.payload.value;
          break;
        case 'main-render':
          state.isVisibleMainRender = action.payload.value;
          break;
        case 'catalog-button':
          state.isVisibleCatalogFormButton = action.payload.value;
          break;
        default:
          break;
      }
    },
    handleOpenAccordion(state, action) {
      switch (action.payload.element) {
        case 'catalogFormOther':
          state.openedAccordion.catalogFormOther = action.payload.value;
          break;
        case 'catalogFormGeneral':
          state.openedAccordion.catalogFormGeneral = action.payload.value;
          break;
        case 'catalogFormModal':
          state.openedAccordion.catalogFormModal = action.payload.value;
          break;
        default:
          break;
      }
    },
  },
});

export const { handleOpen, setScrollPosition, handleVisible, handleOpenAccordion } = ComponentsSlice.actions;

export default ComponentsSlice.reducer;
