const sortFlatsByPrice = (array, promo, direction) => {
  const isPromo = flat => flat.redPrice && flat.mainBenefit;

  const sortPromoAsc = (a, b) => {
    if (isPromo(a) && !isPromo(b)) {
      return -1;
    }
    if (isPromo(b) && !isPromo(a)) {
      return 1;
    }
    return a.currentPrice - b.currentPrice;
  };

  const sortPromoDesc = (a, b) => {
    return sortPromoAsc(b, a);
  };

  const sortAsc = (a, b) => {
    return a.currentPrice - b.currentPrice;
  };

  const sortDesc = (a, b) => {
    return sortAsc(b, a);
  };

  const sortFunc = direction === 'asc_' ? (promo ? sortPromoAsc : sortAsc) : promo ? sortPromoDesc : sortDesc;

  return array.sort(sortFunc);
};

export default sortFlatsByPrice;
