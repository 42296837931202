import { createSelector } from '@reduxjs/toolkit';

export const getShownFlats = state => state.catalogPage.shownFlats;
export const getAllFlats = state => state.catalogPage.allFlats;
export const getBulks = state => state.catalogPage.bulks;

export const getFetchStatus = state => state.catalogPage.urls.getResidential.status;
export const getSortParameters = state => state.catalogPage.sortParameters;
export const getFilterParameters = state => state.catalogPage.filterParameters;

export const getAbleToResetParams = state => state.catalogPage.filterParameters.ableToResetParams;
export const getProjectDeadline = state => state.catalogPage.projectDeadline;
export const getGalleryPhotos = state => state.gallerySlice.photos;
export const getGalleryAboutPhotos = state => state.gallerySlice.aboutPhotos;
export const getGalleryError = state => state.gallerySlice.gallerySliderError;

export const getWidth = state => state.main.width;
export const getHeight = state => state.main.height;
export const getScrollPosition = state => state.componentsState.scrollPosition;
export const getMobileWidth = state => state.main.breakpoint.mobile;
export const getTabletWidth = state => state.main.breakpoint.tablet;
export const getPhoneNumber = state => state.main.contacts.phone;
export const getNewUser = state => state.main.isANewUser;

export const getAmountShownFlats = createSelector([getShownFlats], shownFlats => {
  return shownFlats.length;
});
